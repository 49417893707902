import React, { Component } from "react"

class Meetup67 extends Component {
  componentDidMount() {
    window.location.replace(
      "https://event.on24.com/eventRegistration/EventLobbyServlet?target=reg30.jsp&referrer=https%3A%2F%2Fwcc.on24.com%2Fwebcast%2Fwebcasts&eventid=2821392&sessionid=1&key=E0878DAA4A3EA37F5350FA901D1DC392&regTag=&sourcepage=register"
    )
  }

  render() {
    return <div />
  }
}

export default Meetup67
